import React from "react";
import cx from "classnames";
import styles from "./EnvironmentReporter.module.scss";

const EnvironmentReporter = (): JSX.Element | null => {
  if (process.env.entorno === "pre") {
    return (
      <>
        <div className={cx(styles.root, styles.dev)}>
          <span className={styles.value}>{process.env.entorno}</span>
          <div className={cx(styles.responsive_reporter)} />
        </div>
      </>
    );
  }
  return null;
};

export default EnvironmentReporter;
