export class Logger {
  static log(msg: any) {
    if (process.env.entorno === "pre") {
      console.log(msg);
    }
  }

  static warn(msg: any) {
    if (process.env.entorno === "pre") {
      console.warn(msg);
    }
  }

  static error(msg: any) {
    if (process.env.entorno === "prod") {
      console.error(msg);
    }
  }
}
