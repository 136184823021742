import ReactGA from "react-ga";
import { Logger } from "./logger";

interface TrackEventParams {
  category: string;
  action: string;
  label?: string;
}

export class AnalitycsService {
  public trackPageView(path: string) {
    Logger.log(path);
    ReactGA.pageview(path);
  }

  public trackEvent(args: TrackEventParams) {
    const { action, category, label } = args;
    Logger.log({ action, category, label });
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  }
}
