import "../styles/globals.scss";
import type { AppProps } from "next/app";
import { appWithTranslation } from "next-i18next";
import { createContext, useContext, useEffect, useState } from "react";
import { DefaultSeo } from "next-seo";
import defaultSeo from "@config/default-seo";
import Script from "next/script";
import Head from "next/head";
import { useRouter } from "next/router";
import { AnalitycsService } from "@services/analytics-service";
import EnvironmentReporter from "src/presentation/components/EnvironmentReporter/EnvironmentReporter";

declare var Cookiebot: any; // Cookiebot object
import { settings } from "@config/settings";
import { useRouteChangeErrorHandling } from "@hooks/useRouteChangeErrorHandling";

const AppContext = createContext<any>(null);

const AppProvider = (props: any) => {
  const { value, children } = props;

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export function useAppContext() {
  return useContext(AppContext);
}

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const { country, host } = pageProps;
  const [validate, setValidate] = useState<boolean>(false);

  //Obtener full url
  const canonicalURL = settings.URL_SEO + router.asPath;

  useEffect(() => {
    const checkCookieConsent = () => {
      if (Cookiebot.consented) {
        setValidate(true);
        initializeAnalytics();
        initializeFacebookPixel();
      } else {
        setValidate(false);
      }
    };

    checkCookieConsent();

    // Evento para comprobarlo en cambios de ruta
    const handleRouteChange = () => {
      checkCookieConsent();
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.asPath, router.events, router.pathname]);

  useEffect(() => {
    const handleCookiebotOnAccept = () => {
      setValidate(true);
    };

    const handleCookiebotOnDecline = () => {
      setValidate(false);
    };

    window.addEventListener("CookiebotOnAccept", handleCookiebotOnAccept);
    window.addEventListener("CookiebotOnDecline", handleCookiebotOnDecline);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("CookiebotOnAccept", handleCookiebotOnAccept);
      window.removeEventListener(
        "CookiebotOnDecline",
        handleCookiebotOnDecline
      );
    };
  }, []);

  const initializeAnalytics = () => {
    import("react-ga").then((ReactGA) => {
      // Configuración de Google Analytics
      ReactGA.initialize("UA-92717630-1");
      ReactGA.initialize("G-ZMP04NMGKN");
      ReactGA.initialize("GTM-P47P5VN");
      // Limpiar eventos de cambio de ruta para Analytics
      router.events.off("routeChangeComplete", handleAnalyticsRouteChange);
      router.events.on("routeChangeComplete", handleAnalyticsRouteChange);
    });
  };

  const initializeFacebookPixel = () => {
    import("react-facebook-pixel")
      .then((x) => x.default)
      .then((ReactPixel) => {
        // Configuración de Facebook Pixel
        ReactPixel.init("1295054684023978"); // facebookPixelId
        ReactPixel.pageView();
        // Limpiar eventos de cambio de ruta para Facebook Pixel
        router.events.off("routeChangeComplete", () => {
          ReactPixel.pageView();
        });
        router.events.on("routeChangeComplete", () => {
          ReactPixel.pageView();
        });
      })
      .catch((error) => {
        console.error("Error initializing Facebook Pixel:", error);
      });
  };

  const handleAnalyticsRouteChange = () => {
    new AnalitycsService().trackPageView(window.location.pathname);
  };

  useRouteChangeErrorHandling();
  return (
    <>
      <DefaultSeo {...defaultSeo} />
      <Head>
        <link rel="shortcut icon" href="/favicon.png" />
        <link rel="canonical" href={canonicalURL} />
        {validate === true ? (
          <>
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=UA-92717630-1"
            />
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=G-ZMP04NMGKN"
            />
            <Script
              id="gtag-config"
              dangerouslySetInnerHTML={{
                __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
      
              gtag('js', new Date());
              gtag('config', 'UA-92717630-1', { page_path: window.location.pathname });
              gtag('config', 'G-ZMP04NMGKN', { page_path: window.location.pathname });
              `,
              }}
            />
          </>
        ) : null}
        {host !== undefined ? (
          <>
            {pageProps._nextI18Next.initialLocale === "esp" ? (
              <>
                <link
                  rel="alternate"
                  hrefLang={
                    pageProps.country === undefined
                      ? `es-${country.toUpperCase()}`
                      : `es-${pageProps.country.toUpperCase()}`
                  }
                  href={`${host}${router.asPath}`}
                />
                <link
                  rel="alternate"
                  hrefLang="x-default"
                  href={`${host}${router.asPath}`}
                />
              </>
            ) : (
              <>
                <link
                  rel="alternate"
                  hrefLang={
                    pageProps.paisDeVenta === undefined
                      ? `${
                          pageProps._nextI18Next.initialLocale
                        }-${country.toUpperCase()}`
                      : `${
                          pageProps._nextI18Next.initialLocale
                        }-${pageProps.country.toUpperCase()}`
                  }
                  href={`${host}/en${router.asPath}`}
                />
                <link
                  rel="alternate"
                  hrefLang="x-default"
                  href={`${host}/en${router.asPath}`}
                />
              </>
            )}
          </>
        ) : null}
      </Head>
      {/* Google Tag Manager */}
      {validate === true ? (
        <>
          <Script id="google-tag-manager" strategy="afterInteractive">
            {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-P47P5VN');
      `}
          </Script>
          {/* Google Tag Manager (noscript) */}
          <noscript>
            <iframe
              src="https://www.googletagmanager.com/ns.html?id=GTM-P47P5VN"
              height="0"
              width="0"
              style={{
                display: "none",
                visibility: "hidden",
              }}
            ></iframe>
          </noscript>

          {/* Linkedin pixel */}
          <Script
            id="linkedin-pixel"
            dangerouslySetInnerHTML={{
              __html: `
        _linkedin_partner_id = "1319516"; window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; window._linkedin_data_partner_ids.push(_linkedin_partner_id);`,
            }}
          />
        </>
      ) : null}

      <Script
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        strategy="afterInteractive"
        data-cbid="c5effecd-c2fe-49c4-9c00-f64d089580c4"
        data-blockingmode="auto"
        type="text/javascript"
      />
      <Script
        src={settings.google.URL_BIOVERT_GOOGLE_RECAPTCHA_KEY}
        strategy="afterInteractive"
      ></Script>

      {validate === true ? (
        <Script
          src="https://snap.licdn.com/li.lms-analytics/insight.min.js"
          strategy="afterInteractive"
          onLoad={() => {
            var s = document.getElementsByTagName("script")[1];
            var b = document.createElement("script");
            b.type = "text/javascript";
            b.async = true;
            b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
            s.parentNode?.insertBefore(b, s);
          }}
        />
      ) : null}
      <AppProvider value={pageProps}>
        <Head>
          <meta
            name="google-site-verification"
            content="bqRlAEN3KdQEm_OR_2vW78QZZM0cDN6L9CVssJ5DcRA"
          />
          <meta
            name="facebook-domain-verification"
            content="1nlckolkn22z7syqreetwbccjke94f"
          />
          <noscript>
            {`
              <img
              height="1"
              width="1"
              src="https://www.facebook.com/tr?id=1295054684023978&ev=PageView
              &noscript=1"
              />
              `}
          </noscript>
          <noscript>
            {`
            <img
              height="1"
              width="1"
              style="display: none"
              alt=""
              src="https://px.ads.linkedin.com/collect/?pid=1319516&fmt=gif"
            />
            `}
          </noscript>
        </Head>
        <Component {...pageProps} />

        <EnvironmentReporter />
      </AppProvider>
    </>
  );
}

export default appWithTranslation(MyApp);
MyApp.getInitialProps = async (context: {
  req: any;
  query: any;
  res: any;
  asPath: any;
  pathname: any;
}) => {
  const { req } = context;
  if (req) {
    let host = req.headers.host;
    return host;
  }
};
