import { NextSeoProps } from "next-seo";

const defaultSeo: NextSeoProps = {
  title: "manvert | Bioestimulantes y nutrientes avanzados para tus cultivos",
  description:
    "Manvert proporciona soluciones de calidad para mejorar el rendimiento de tus cultivos. Bioestimulantes y nutrientes respetuosos con el medio ambiente.",
  openGraph: {
    type: "website",
    locale: "esp",
    url: "https://manvert.com",
    site_name:
      "manvert | Bioestimulantes y nutrientes avanzados para tus cultivos",
    images: [
      {
        secureUrl: "https://manvert.com/static/manvert-meta.png",
        type: "image/jpg",
        url: "https://manvert.com/static/manvert-meta.png",
        alt: "manvert",
      },
    ],
  },
  twitter: {
    handle: "@handle",
    site: "@site",
    cardType: "summary_large_image",
  },
};

export default defaultSeo;
