import { useEffect } from "react";
import { useRouter } from "next/router";

export function useRouteChangeErrorHandling() {
  const router = useRouter();

  useEffect(() => {
    // Verifica si la ruta actual en el enrutador no es "/404".
    if (router.pathname !== "/404") {
      // Manejo de un error cuando el usuario cambia de ruta antes de que se cargue completamente la nueva URL o realiza un doble clic en la misma.

      // Definición de una función llamada handleRouteChangeError que toma un objeto err y una URL como parámetros.
      const handleRouteChangeError = (err: { cancelled: any }, url: any) => {
        // Si el cambio de ruta fue cancelado (err.cancelled es verdadero), recarga la página.
        if (err.cancelled) {
          console.log(`Route to ${url} was cancelled!`);
          router.reload();
        }
      };

      // Registra un oyente de eventos para el evento "routeChangeError" del enrutador
      // y asocia la función handleRouteChangeError como su manejador.
      router.events.on("routeChangeError", handleRouteChangeError);

      // Si el componente se desmonta, cancela la suscripción al evento utilizando el método "off":
      return () => {
        router.events.off("routeChangeError", handleRouteChangeError);
      };
    }
  }, [router]);

  // Devuelve un valor o realiza cualquier lógica adicional si es necesario
}
